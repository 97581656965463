<template>
      
    <!-- Drawer container -->
    <div 
        v-if="visible" 
        id="right-drawer" 
        :class="[ visible ? 'drawer-visible' : '', 'right-drawer' ]"
    >

        <!-- Close button -->
        <div>
            <svg 
                class="close" 
                @click="hideDrawer" 
                viewBox="0 0 512 512"
            >
                    <path fill="#bdbdbd" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
            </svg>
        </div>

        <!-- Header -->
        <div v-if="!loading" class="drawer-header" id="drawer-header">
            <div class="ant-skeleton ant-skeleton-with-avatar">
                <div class="content-header">

                    <!-- Card surrounded by the color of the intelligence. -->
                    <div class="mini-card" 
                        :style="{border: '2px solid ' + intelligence.rgb_color}"
                    >
                        <div class="inline-block">

                            <!-- Content icon -->
                            <div>
                                <img v-if="content.icon" :src="content.icon" :alt="content.title" />
                                <span v-else class="alt-icon fa-stack fa-2x">
                                    <i class="fas fa-square fa-stack-2x"></i>
                                    <i class="fas fa-shapes fa-stack-1x fa-inverse"></i>
                                </span>
                            </div>
                            
                            <!-- Content name -->
                            <span class="content-title">
                                <span class="title">
                                    {{ content.title }}
                                </span>
                                <p class="content-type" v-if="content.type">{{ typeName }}</p>
                            </span>
                            
                            <!-- Button to add -->
                            <span 
                                v-if="showAddButton" 
                                class="mini-button" 
                                @click="$emit('toggle-select-content', content)"
                                :style=" isAlreadyAdded ? 'background: red;' : 'background: #2bc9ee;' "
                            >
                                    {{ isAlreadyAdded ? 'Quitar' : 'Añadir' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- SKELETON -->
        <div v-else class="drawer-header">
            <skeleton-with-avatar-active v-for="index in 1" :key="index"></skeleton-with-avatar-active>
        </div>

        <div v-if="!loading" class="drawer-container " id="drawer-container">
                <div class="ant-skeleton-content">
                    
                    <div class="bloque">
                        <p class="content-desc" v-if="intelligence">
                            <span class="text-center" style="width:40px;">
                                <img height="30" :src=" intelligence.icon "> 
                            </span>
                            <span>{{intelligence.name.replaceAll('{0}', 'Smile and Learn')}}</span>
                        </p>
                        <p class="content-desc" v-if="content.min_age && content.max_age">
                            <span class="text-center" style="width:40px;">
                                <svg viewBox="0 0 384 512" style="margin:0;" width="24" height="24"><path fill="#006f87" d="M120 72c0-39.765 32.235-72 72-72s72 32.235 72 72c0 39.764-32.235 72-72 72s-72-32.236-72-72zm254.627 1.373c-12.496-12.497-32.758-12.497-45.254 0L242.745 160H141.254L54.627 73.373c-12.496-12.497-32.758-12.497-45.254 0-12.497 12.497-12.497 32.758 0 45.255L104 213.254V480c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V368h16v112c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V213.254l94.627-94.627c12.497-12.497 12.497-32.757 0-45.254z"/></svg>
                            </span>
                            <span>{{content.min_age}} - {{content.max_age}} años</span>
                        </p>
                        <p class="content-desc" v-if="content.levels && content.levels.length > 0">
                            <span class="text-center" style="width:40px;">
                                <svg viewBox="0 0 640 512" style="margin:0;" width="24" height="24"><path fill="#006f87" d="M216 288h-48c-8.84 0-16 7.16-16 16v192c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V304c0-8.84-7.16-16-16-16zM88 384H40c-8.84 0-16 7.16-16 16v96c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16v-96c0-8.84-7.16-16-16-16zm256-192h-48c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm128-96h-48c-8.84 0-16 7.16-16 16v384c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V112c0-8.84-7.16-16-16-16zM600 0h-48c-8.84 0-16 7.16-16 16v480c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16z"/></svg>
                            </span>
                            <span>
                                <span v-for="(l, index) in content.levels" :key="index">{{ levelName(l) }}<span v-if="index != content.levels.length - 1">, </span>
                                </span>
                            </span>
                        </p>
                    </div> 
                    <div class="bloque" v-if="content.description">
                        <div class="inline-block subtitle">
                            <span> Descripción </span>
                        </div>
                        <p>{{content.description}}</p>
                    </div>


                    <div class="bloque" v-if="content.url">
                        <div class="inline-block subtitle">
                            <span> Previsualización </span>
                        </div>
                        <div v-if="content.type != 'video' && content.type != 'customaudiobook'">
                            <svg 
                                class="full" 
                                @click="enableFullScreen" 
                                viewBox="-4 -4 32 32"
                            >
                                <path fill="#ffffff" d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"/>
                            </svg>
                        </div>
                        <div width="100%">
                            <video 
                                v-if="content.type == 'video'"
                                :title="content.title" 
                                crossorigin="anonymous"
                                class="content-frame"
                                oncontextmenu="return false;"
                                controls
                                disablePictureInPicture 
                                controlsList="nodownload"
                            >
                                <source :src="contentUrl" type="video/mp4">
                                <track 
                                    v-for="([trackLang, trackUrl]) in filterCaptions(content.captions)" 
                                    :key="trackLang"
                                    :label="capitalize(langName(trackLang))" 
                                    kind="subtitles" 
                                    :srclang="trackLang" 
                                    :src="trackUrl" 
                                    :default="trackLang == lang"
                                />
                            </video>
                            <audio 
                                v-if="content.type == 'customaudiobook'"
                                :title="content.title" 
                                style="width: 100%;"
                                oncontextmenu="return false;"
                                controls
                                controlsList="nodownload noplaybackrate"
                            >
                                <source :src="contentUrl" type="audio/mp3">
                            </audio>
                            <iframe 
                                v-if="content.type != 'video' && content.type != 'customaudiobook'"
                                id="webgl"
                                :title="content.title"
                                class="content-frame"
                                :src="contentUrl" 
                                frameborder="0"
                                allowfullscreen
                            >
                            </iframe>
                            <a v-if="content.type=='pdf'" 
                                class="mini-button"
                                @click.stop 
                                target="_blank"
                                :href="contentUrl"
                                :style=" 'background: #2bc9ee; margin-top:5px' "
                            >
                                Descargar
                            </a>
                        </div>
                    </div>

                    <div class="bloque" v-if="content.academic_areas && content.academic_areas.length > 0">
                        <div class="inline-block subtitle">
                            <span> Áreas Académicas </span>
                        </div>
                        <span>
                            <div :class="'mini-tag lp-color-' + intelligence.id"
                            v-for="(aa, index) in content.academic_areas" :key="index">
                                {{ formatString(aa)}}
                            </div>
                        </span>
                    </div>

                    <div class="bloque" v-if="content.cognitive_skills && content.cognitive_skills.length > 0">
                        <div class="inline-block subtitle">
                            <span>  Habilidades Cognitivas </span>
                        </div>
                        <span>
                            <div :class="'mini-tag lp-color-' + intelligence.id"
                            v-for="skill in content.cognitive_skills" :key="skill.id">
                                {{  formatString(skill)}}
                            </div>
                        </span> 
                    </div>
                    <div class="bloque" v-if="content.competences && content.competences.length > 0">
                        <div class="inline-block subtitle">
                            <span> Competencias Clave</span>
                        </div>
                        <span>
                            <div :class="'mini-tag lp-color-' + intelligence.id"
                            v-for="competence in content.competences" :key="competence.id">
                                {{ formatString(competence) }}
                            </div>
                        </span> 
                    </div>
                    <div class="bloque" v-if="content.guide_url">
                        <div class="inline-block subtitle">
                            <span> Guía Didáctica</span>
                        </div>
                        <p class="content-desc">
                            <span class="text-center" style="width:40px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #006f87;transform: ;msFilter:;"><path d="M19 2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.806 5 19c0-.101.009-.191.024-.273.112-.576.584-.717.988-.727H21V4a2 2 0 0 0-2-2zm0 9-2-1-2 1V4h4v7z"></path></svg>
                            </span>
                            <a :href="content.guide_url" target="_blank">Consulta la guía didáctica</a>
                        </p>
                    </div>
                    
                </div>
        </div>

        <!-- SKELETON -->
        <div v-else class="drawer-container">
            <skeleton-with-avatar-active v-for="index in 5" :key="index"></skeleton-with-avatar-active>
        </div>
    </div>
    
    <!-- MASK -->
    <div v-if="visible" class="drawer-mask"
        @click="hideDrawer"
        :style="{
            width: visible ? '100vw' : '0',
            opacity: visible ? '0.6' : '0',
            top: '0px',
            height: '100vh', //'calc(100vh - 95px)'
        }">
    </div>

</template>

<script>

// Constants
import { LANG_NAMES, LEVEL_NAMES, TYPE_NAMES } from '@/lib/constants'

// Methods
import { getContentDetails } from '@/lib/functions'

// Graphic components.
import SkeletonWithAvatarActive from './SkeletonWithAvatarActive.vue'


export default {

    // Component name.
    name: "DrawerContent",

    // Components.
    components: {
        SkeletonWithAvatarActive    
    },

    // Data.
    data () {
        return {
            // Whether the loading process is taking place.
            loading: false,
         
            // Content details.
            content: null,
        }
    },

    // Input properties.
    props: {
        // Whether the drawer must be displayed.
        visible: Boolean,

        // API token.
        token: String,

        // ID of the content whose details are shown.
        contentId: Number,

        // Language to show details.
        lang: String,

        // Whether to show the button to add the content to the activity.
        showAddButton: Boolean,
        
        // Whether the content is already added to the activity.
        isAlreadyAdded: Boolean,
    },

    // Emits.
    emits: [
        'toggle-select-content',
        'manage-drawer',
    ],

    // Computed properties.
    computed: {

        /**
         * URL of the content.
         */
        contentUrl () {
            if (['pdf', 'video', 'customaudiobook', 'customquiz'].includes(this.content.type)) {
                return this.content.url[this.lang];
            } else {
                return this.content.url
            }
        },

        /**
         * Intelligence details
         */
        intelligence () {
            return {
                "id" : this.content?.intelligences[0].id,
                "name": this.content?.intelligences[0].name,
                "rgb_color": this.content?.intelligences[0].rgb_color,
                "icon": this.content?.intelligences[0].icon,
            }
        },

        /**
         * Properly formatted content type name
         */
        typeName () {
            return this.content ? TYPE_NAMES[this.content.type] : ''
        },
    },

    // Triggers.
    watch: {

        /*
         * When the drawer is shown, the content details are retrieved.
         */
        async visible () {
            if (this.visible) {
                this.loading = true 
                this.content = await getContentDetails(this.token, this.lang, this.contentId)
                this.loading = false
            }
        },
    },

    // Methods.
    methods: {

        /**
         * Properly formats a string for a nice display.
         */
        formatString (s) {
            return s.name ?? s.id.charAt(0).toUpperCase + s.id.slice(1).replaceAll('_', ' ')
        },

        /**
         * Displays a string with the first letter capitalized.
         */
        capitalize (s) {
            return s.charAt(0).toUpperCase() + s.substr(1)
        },

        /**
         * Properly formatted content type name.
         */
        langName (lang) {
            return LANG_NAMES[lang]
        },

        /**
         * Properly formatted level name.
         */
        levelName (l) {
            return LEVEL_NAMES[l]
        },

        /** 
         * Hides the drawer.
         */
        hideDrawer () {
            this.$emit('manage-drawer', null)
        },

        /**
         * URLs of available captions.
         */
        filterCaptions (captions) {
            return Object.entries(captions).filter((c) => c[1] != '')
        },

        /**
         * Enables full screen mode.
         */
        enableFullScreen () {
            document.getElementById('webgl').requestFullscreen()
        }
    },
    
}
</script>

<style scoped>

/* General drawer properties */
.right-drawer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px minmax(100px, auto) 1fr;
  grid-template-areas: "close" "header" "main";
  justify-items: stretch;
  align-items: start;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  overflow: hidden;
  height: 100%;
  padding-left: 0;
  border-left: 1px solid whitesmoke;
  background: white;
  z-index: 400;
  transition: all 0.2s;
}

/* Drawer properties when displayed: small screens */
@media only screen and (max-width: 600px) {
    .drawer-visible {
        width: 80vw !important;
        height: 100%;
    }
}

/* Drawer properties when displayed: medium screens */
@media only screen and (min-width: 600px) {
    .drawer-visible {
        width: 50vw !important;
        height: 100%;
    }
}

/* Drawer properties when displayed: large screens */
@media only screen and (min-width: 992px) {
    .drawer-visible {
        width: 30vw !important;
    }
}

/* Content frame */
.content-frame {
    width: 100%;
    height: calc(20vw * 3 / 4);
}

/* Close button */
.close {
    margin: 8px 0 0 18px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/* Full screen button */
.full {
    background-color: #bdbdbd;
    border-radius: 12px;
    float: right;
    margin: -2em 0 0 18px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/* Drawer header */
.drawer-header {
    grid-area: header;
    height: fit-content;
    padding: 0 1rem;
}

/* Skeleton */
.ant-skeleton {
    display: table;
    width: 100%;
    padding: 5px 0;
}

/* Skeleton content */
.ant-skeleton-content {
    width: 100%;
    vertical-align: top;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 8px;
}

/* Skeleton with avatar */
.ant-skeleton-with-avatar {
    margin-top: 5px;
    margin-bottom: 8px;
}

/* Content header */
.content-header {
    display: table-cell;
    vertical-align: top;
}

/* Mini card within the header */
.mini-card {
    margin: 5px;
    overflow: hidden;
    padding: 10px;
    border-radius: 10px;
}

/* Alternative icon */
.alt-icon {
    color: rgba(58, 140, 181, 0.2);
}

/* Content title */
.content-title {
    text-align: left;
    vertical-align: top;
    margin-left: 10px;
    width: calc(100% - 84px);
}
.title {
    font-weight: bold;
    font-size: 18px;
}

.content-type {
    margin-top: 0;
}

.content-desc {
    display: flex;
    align-items: center;
}

.subtitle {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: bold;    
    margin: 8px 0;    
}

.bloque {
    margin: 15px 0;
}

.mini-tag{
    margin: 3px;
    display: inline-block;
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 20px;
}

.mini-button{
        margin: 3px;
    color: #fff;
    cursor: pointer;
    /* border-color: #87e8de; */

    box-sizing: border-box;

    font-variant: tabular-nums;
    list-style: none;
    display: inline-block;
    height: auto;
    padding: 3px 10px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 20px;
    opacity: 1;
    transition: all .3s;
}
.content-header > img {
    border: 1px solid #cacaca;
    background-color: #e5e5e5;
    border-radius: 10px;
    box-shadow: 0 1px 2px darkgray;
}



/* DRAWER */
.drawer-container{
    grid-area: main;

    /* max-height: calc(100% - 160px); */
    height: 100%;
    overflow-y: auto;
    padding: 0 1.5rem;  
    /* border-top: 1px solid #e5e5e5; */
}


/* BASE & MASK */
.drawer-mask {
  position: fixed;
  left: 0;
  top: 95px;
  width: 0; /* initially */
  /* height: 100vh; */
  background: rgb(0, 0, 0);
  opacity: 0.3;
  z-index: 299;
}

</style>