<template>

  <div class="inline-block">

    <!-- Content info -->
    <span class="content-name">
      <img :src="content.icon" :alt="content.title">
      <div>
        <p class="title"> {{ content.title }} </p>
        <p class="type"> {{ typeName(content.type) }} </p>
      </div>
    </span>

    <span class="icon-set">
      <!-- Completed sign -->
      <svg v-if="completed" class="green" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
      </svg> 
      
      <!-- Play button -->
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
      </svg>              
    </span>
  </div>

</template>

<script>
import { 
  TYPE_NAMES,
} from '@/lib/constants'

export default {

  // Input properties.
  props: {
    // The content (item).
    content: Object,

    // Whether the content is completed or not.
    completed: Boolean,
  },

  // Methods.
  methods: {
    /**
     * Properly formatted content type name.
     */
    typeName (type) {
      return TYPE_NAMES[type]
    },
  }
}
</script>


<style scoped>

.content-name {
  text-align: left;
}
.content-name img {
  float: left;
  margin-right: 10px; 
  cursor: pointer; 
  height: 40px;
}
.content-name div {
  margin-left: 50px;
}
.content-name .title {
  margin: 0;
  font-weight: 500;
}
.content-name .type {
  margin: 0;
  font-weight: 100;
}
.icon-set {
  width: 60px;
}
.icon-set svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: rgba(0, 111, 135, 1);
}
.green {
  fill: green !important;
}
</style>