<template>
  <div  class="ant-skeleton ant-skeleton-with-avatar ant-skeleton-active">
                    <div class="ant-skeleton-header">
                        <span class="fa-stack fa-2x">
                        <i class="fas fa-square fa-stack-2x"></i>
                        <i class="fas fa-shapes fa-stack-1x fa-inverse"></i>
                    </span>
                    </div>
                    <div class="ant-skeleton-content">
                        <h3 class="ant-skeleton-title" style="width: 50%;"></h3>
                        <ul class="ant-skeleton-paragraph">
                            <li></li><li></li>
                        </ul>
                    </div>
                </div>
</template>

<script>
export default {
    name: "SkeletonWithAvatarActive"
}
</script>

<style scoped>
.fa-stack{
    color: rgba(58, 140, 181, 0.2);
}
.ant-skeleton{
    display: table;
    width: 100%;

    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0;
}
.ant-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    background: linear-gradient(
90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li {
    background: linear-gradient(
90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li {
    background: linear-gradient(
90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
}
@keyframes ant-skeleton-loading{
    0%{background-position:100% 50%}
    to{background-position:0 50%}
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: rgba(58, 140, 181, 0.2);
}
.ant-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 5px;
    margin-bottom: 8px;
}
.ant-skeleton-content .ant-skeleton-title {
    height: 16px;
    background: rgba(58, 140, 181, 0.2);
    border-radius: 4px;
}

.ant-skeleton-content .ant-skeleton-paragraph {
    padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph>li {
    width: 100%;
    height: 8px;
    list-style: none;
    background: rgba(58, 140, 181, 0.2);
    border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph>li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph>li+li {
    margin-top: 8px;
}
</style>