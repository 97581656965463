<template>


    <!-- Drawer container -->
    <div 
        v-if="visible" 
        id="right-drawer" 
        :class="[ visible ? 'drawer-visible' : '', 'right-drawer' ]"
    >

        <!-- Close button -->
        <div>
            <svg 
                class="close" 
                @click="hideDrawer" 
                viewBox="0 0 512 512"
            >
                <path fill="#bdbdbd" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
            </svg>
        </div>

        <!-- Header -->
        <div v-if="!loading" class="drawer-header" id="drawer-header">
            <div class="ant-skeleton ant-skeleton-with-avatar">
                <div class="content-header">

                    <!-- Card surrounded by the color of the intelligence. -->
                    <div class="mini-card">
                      <div class="inline-block-flex-start">

                            <!-- Content icon -->
                            <img v-if="route.icon_url" class="route-icon far fa-square" :src="route.icon_url" alt="icon" />
                            <span v-else class="fa-stack fa-2x">
                                <i class="fas fa-square fa-stack-2x"></i>
                                <i class="fas fa-shapes fa-stack-1x fa-inverse"></i>
                            </span>
                          
                            <!-- Route name -->
                            <span class="route-title">
                                <span class="title"> 
                                    {{ route.name }} 
                                </span>
                            </span>
                      </div>
                  </div>

                </div>
            </div>
        </div>



        <!-- Body -->
        <div v-if="!loading" class="drawer-container " id="drawer-container">
            <div class="ant-skeleton-content">
           
                <!-- Route description -->
                <div class="bloque" v-if="route.description">
                    <div class="inline-block subtitle">
                        <span> Descripción </span>
                    </div>
                    <p>
                        {{ route.description }}
                    </p>
                </div>

                <!-- Contents details -->
                <div class="bloque" v-if="route.contents?.length > 0">
                    <div class="inline-block subtitle">
                        <span v-if="course == null">Objetos de Aprendizaje</span>
                        <span v-if="course != null">Secuencias Didácticas</span>
                    </div>
                    <span>
                        <div v-for="content in route.contents" :key="content" class="route-content-with-icon">
                        
                            <!-- Content icon -->
                            <div class="route-content-icon">
                                <img v-if="content.icon_url" :src="content.icon_url" alt="icon">
                            </div>

                            <div class="route-content-header">

                                <!-- Content name -->
                                <p class="route-content-header-title">
                                    {{ content.name }}
                                </p>
                     

                                <span v-if="content.description?.length < 50">
                                    <p class="route-content-header-desc">
                                        {{ content.description }}
                                    </p>
                                </span>
                                <span v-else-if="content.description?.length >= 50"> 
                                    <span v-if="readMore[content.id]">
                                        <p class="route-content-header-desc">
                                            <svg @click="showMoreOrLess(content.id)" class="pointer readMore" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 11h14v2H5z"></path>
                                            </svg>
                                            {{content.description}}
                                        </p>
                                    </span>
                                    <span v-else>
                                        <p class="route-content-header-desc">
                                            <svg @click="showMoreOrLess(content.id)" class="pointer readMore" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                                            </svg>
                                            {{ content.description.substring(0, 50) + "..." }}
                                        </p>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </span> 
                </div>            
            </div>
        </div>
    </div>
    
    <!-- Mask -->
    <div 
        v-if="visible" 
        class="drawer-mask"
        @click="hideDrawer"
        :style="{
            width: visible ? '100vw' : '0',
            opacity: visible ? '0.6' : '0',
            top: '0px',
            height: '100vh',
        }">
    </div>

</template>

<script>

// Methods
import { getRouteDetails } from '@/lib/functions'

export default {

    // Component name.
    name: "DrawerRoute",

    // Input properties.
    props: {
        // Whether the drawer must be displayed.
        visible: Boolean,

        // API token.
        token: String,

        // ID of the route whose details are shown.
        routeId: Number,

        // Language to show details.
        lang: String,

        // Course info (for course routes).
        course: Object,
    },

    // Emits.
    emits: [
        'manage-drawer',
    ],

    // Data
    data () {
        return {
            // Whether the loading process is taking place.
            loading: false,

            // Whether a content detail is expanded (read more).
            readMore: {},
        }
    },

    // Triggers.
    watch: {

        /*
         * When the drawer is shown, the route details are retrieved.
         */
        async visible () {
            if (this.visible && this.routeId) {
                this.loading = true 
                this.route = await getRouteDetails(this.token, this.lang, this.routeId)
                this.loading = false
                this.readMore = Object.fromEntries(this.route.contents.map( c => [c.id, false]))
            }

            if (this.visible && this.course) {
                this.route = this.course
                this.readMore = Object.fromEntries(this.route.contents.map( c => [c.id, false]))
            }
        },
    },

    // Methods.
    methods: {
        
        /**
         * Shows more or shows less text in the description.
         */
        showMoreOrLess (contentId) {
            this.readMore[contentId] = !this.readMore[contentId]
        },

        /** 
         * Hides the drawer.
         */
        hideDrawer () {
            this.$emit('manage-drawer', null)
        },
  }
}
</script>

<style scoped>
.subtitle{
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    
    /* color: #3a81a5; */
    font-weight: bold;
    /* border-bottom: 1px solid lightgrey; */
    
    margin: 8px 0;    
}
.icon-stack{
    height: 65px;  
    margin: 5px 2rem 5px 0;
}

.route-icon {
    height: 65px;  
    margin: 5px 5px 5px 0;
}


/* Close button */
.close {
    margin: 8px 0 0 18px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/* For mobile phones: */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    .drawer-visible{
        width: 80vw  !important;
        /* top: 95px !important; */
        height: 100%;
    }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
    .drawer-visible{
        width: 50vw  !important;
        /* top: 95px !important; */
        height: 100%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .drawer-visible{
        width: 30vw  !important;
        /* top: 95px !important; */
        /* height: calc(100vh - 95px) !important; */
    }
}

.mini-card{
    margin: 5px;
    overflow: hidden;
    padding: 10px;
    border: '2px solid #88BEEF';
    /* background-color: #13c2c2; */
    /* color: #a5a5a5; */
    border-radius: 10px;
   /*  border: 1px solid  #13c2c2; */
}
.mini-card-body{
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
}
.mini-card-body-icon{
    margin-right: 20px;
}
.mini-card-body-content{
    flex: 1;
}
.mini-card-body-content .title{
    font-size: 22px;
   /*  font-weight: bold; */
}
.mini-card-body-content .sub{
    font-size: 12px;
}
.mini-card-sm{
    margin: 5px;
    overflow: hidden;
    padding: 10px;
    /* background-color: #13c2c2; */
    /* color: #13c2c2; */
    border-radius: 10px;
    /* border: 1px solid  #13c2c2; */
    width: 50%;
}
.mini-card-body-sm{
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    
}


.bloque{
    margin: 15px 0;
}
.tag-cyan{
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;

}
.tag{

    box-sizing: border-box;
    margin: 0 8px 0 0;
    font-variant: tabular-nums;
    list-style: none;
    display: inline-block;
    height: auto;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;

}

.mini-tag{
    margin: 3px;
    display: inline-block;
    padding: 3px 10px;
    font-size: 12px;
    
    border-radius: 20px;

}
.mini-button{
        margin: 3px;
    color: #fff;
    cursor: pointer;
    /* border-color: #87e8de; */

    box-sizing: border-box;

    font-variant: tabular-nums;
    list-style: none;
    display: inline-block;
    height: auto;
    padding: 3px 10px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 20px;
    opacity: 1;
    transition: all .3s;
}
.content-header > img {
    border: 1px solid #cacaca;
    background-color: #e5e5e5;
    border-radius: 10px;
    box-shadow: 0 1px 2px darkgray;
}
.route-title{
    margin: 0 10px;
    text-align:left;
    width: 100%;
}
.route-title {
    grid-area: title;
    white-space: normal;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.route-title .title{
    font-weight: bold;
    font-size: 16px;
}

.content-header {
    display: table-cell;
    /* padding-right: 8px; */
    vertical-align: top;
}
.content-desc{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.fa-stack{
    color: rgba(58, 140, 181, 0.2);
}
.ant-skeleton{
    display: table;
    width: 100%;

    ;
    padding: 5px 0;
}
.ant-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
    padding: 0;
}
.ant-skeleton-with-avatar .ant-skeleton-content {
    margin-top: 5px;
    margin-bottom: 8px;
}


/* DRAWER */
.drawer-container{
    grid-area: main;

    /* max-height: calc(100% - 160px); */
    height: 100%;
    overflow-y: auto;
    padding: 0 1.5rem;  
    /* border-top: 1px solid #e5e5e5; */
}
.drawer-header{
    grid-area: header;

    height: fit-content;
    /* color: #3a8cb5; */
    padding: 0 1rem;
    /* border-bottom: 1px solid #e5e5e5 */
}

/* BASE & MASK */
.drawer-mask {
  position: fixed;
  left: 0;
  top: 95px;
  width: 0; /* initially */
  /* height: 100vh; */
  background: rgb(0, 0, 0);
  opacity: 0.3;
  z-index: 299;
}
.right-drawer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px minmax(100px, auto) 1fr;
  grid-template-areas: "close" "header" "main";
  justify-items: stretch;
  align-items: start;

  text-align: left;

  position: fixed;
  top: 0;
  right: 0;
  width: 0; /* initially */
  overflow: hidden;
  height: 100%;
  padding-left: 0; /* initially */
  border-left: 1px solid whitesmoke;
  background: white;
  z-index: 400;
  transition: all 0.2s; /* for the animation */
}

.inline-block-flex-start{
  display: flex;
    align-items: center;
    justify-content: flex-start;
}

.route-content-with-icon{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0;
}

.route-content-with-icon .route-content-icon{
    display: table-cell;
    padding-right: 8px;
    vertical-align: top;
}
.route-content-with-icon .route-content-icon img{
    height: 50px;
    margin: 5px 0px;
    border: 1px solid #cacaca;
    background-color: #e5e5e5;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 2px darkgrey;
    box-shadow: 0 1px 2px darkgrey;
}
.route-content-with-icon .route-content-header{
    margin-top: 5px;
    margin-bottom: 8px;
}
.route-content-with-icon .route-content-header .route-content-header-title{
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 15px;
}
.route-content-header-desc{
    margin:0px;
}

.readMore {
    vertical-align: text-bottom;
    width: 20px;
    height: 20px;
}
</style>