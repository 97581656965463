<template>

  <!-- Header -->
  <smile-header :welcome="true" />

  <!-- Container -->
  <div class="contents-outer">   
    <div class="contents-inner">
      <h3>Selecciona:</h3>

      <!-- Selector -->
      <div class="selector">
        <div class="option" @click="$router.push({name: 'CatalogContents', query: {'apiKey': apiKey, 'token': token, 'activityId': activityId}})">
          <img src="../assets/icon_contents.png" alt="icon">
          <p>Objetos de Aprendizaje</p>
          <small>ODE-2</small>
        </div>
        <div v-if="!restricted" class="option" @click="$router.push({name: 'CatalogRoutes', query: {'apiKey': apiKey, 'token': token, 'activityId': activityId}})">
          <img src="../assets/icon_paths.png" alt="icon">
          <p>Secuencias Didácticas</p>
          <small>ODE-3</small>
        </div>
        <div v-if="!restricted" class="option" @click="$router.push({name: 'CatalogCourses', query: {'apiKey': apiKey, 'token': token, 'activityId': activityId}})">
          <img src="../assets/icon_course.png" alt="icon">
          <p>Programas de Formación</p>
          <small>ODE-4</small>
        </div>
        <div v-if="!restricted">
          <div v-for="challenge in challenges" :key="challenge.id" class="option" @click="$router.push({name: 'ActiveChallenge', query: {'apiKey': apiKey, 'token': token, 'activityId': activityId, 'challengeId': challenge.id}})">
            <img :src="challenge.image_url" alt="icon">
            <p>{{ challenge.title }}</p>
            <small>{{ challenge.description }}</small>
          </div>
        </div>
      </div>
    </div>        
  </div>

</template>


<script>
// Methods
import { validateApiKey, registerNewActivity, getActiveChallenges } from '@/lib/functions'

// Graphic components
import SmileHeader from '@/components/SmileHeader.vue'

export default {

  // Components.
  components: {
    SmileHeader,
  },

  // Data.
  data () {
    return {
      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Activity id.
      // A new activity will be created in "create" mode.
      activityId: null,

      // Check if the client is restricted (only shows ODE-2).
      restricted: true,

      // Active challenges.
      challenges: [],
    }
  },
  
  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    // Might be only API key (when accessed from Moodle).
    // Can also include token and activityId (when redirected from another screen).
    this.apiKey = this.$route.query.apiKey
    this.token = this.$route.query.token
    this.activityId = this.$route.query.activityId

    // Validates the API key.
    let client = await validateApiKey(this.apiKey)
    if (!client) this.$router.push('/no-license')
    this.restricted = client.is_restricted

    // Listener for capturing Moodle events.
    window.onmessage = (event) => this.handleMoodle(event.data)
    
    // Registers a new activity (only if activity id is not available).
    if (!this.activityId) {
      this.activityId = await registerNewActivity(this.apiKey)
      if (this.activityId) this.messageActivity()
      else this.$router.push('/no-license')
    }

    // Retrieves the active challenges.
    this.challenges = await getActiveChallenges(this.token)

    this.messageReady()
  },

  // Methods.
  methods: {

    /**
     * Messages the activity id to the parent (e.g. Moodle).
     */
    messageActivity () {
      parent.postMessage({
        type: 'smile.lti.activity',
        activityId: this.activityId,
      }, '*')
    },

    /**
     * Messages to the parent when ready.
     */
     messageReady () {
      parent.postMessage({
        type: 'smile.lti.ready',
      }, '*')
    },

    /** 
     * Handle Moodle events.
     * In this page, Moodle can only send the API token.
     */
    handleMoodle (data) {
      if (data.type == 'smile.moodle') {
        this.token = data.token
      }
    },
  },
}
</script>


<style scoped>
.contents-outer {
  text-align: center; 
  margin-top: 20px;
}
.contents-inner {
  background-color: white; 
  max-width: 800px; 
  margin: auto; 
  padding: 1em 0;
  border-radius: 10px;
}
.selector {
  display: flex; 
  justify-content: space-evenly;
}
.option {
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}
.option img {
  cursor: pointer; 
  height: 140px;
}
.option p {
  margin-bottom: 0px;
}
.option small {
  color: lightgray;
  font-size: 12px;
}
.option:hover {
  background-color: rgb(240, 240, 240);
}
</style>