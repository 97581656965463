<template>

  <!-- Container -->
  <div class="content-container text-center pointer">

    <!-- Content icon -->
    <div class="content-img" @click="$emit('toggle-select-content', item)">
      <img 
        :class="[ isSelected ? 'content-selected' : 'content-not-selected' ]"
        :src="item.icon_url"
        :alt="item.name"
        :title="item.name"
      />
    </div>

    <!-- Content title -->
    <div class="content-title" @click="$emit('toggle-select-content', item)">
      <h4 class="dark-blue" :title="item.name">
        {{ item.name }}
      </h4>
    </div>

    <!-- Info (details) button -->
    <div class="content-info">
      <span>
        <svg 
          class="pointer"
          xmlns="http://www.w3.org/2000/svg"
          @click="$emit('manage-drawer', item.id)"   
        >
          <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
        </svg>
      </span>
    </div>
  </div>

</template>

<script>
export default {

  // Input properties.
  props: {

    // The content (item).
    item: {
      type: Object,
      required: true,
    },

    // Whether the item is selected.
    isSelected: {
      type: Boolean,
      required: true,
    },
  },

  // Emits.
  emits: [
    'toggle-select-content',
    'manage-drawer',
  ],
}
</script>

<style scoped>
.content-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 50px 24px;
  grid-template-areas: "img" "title" "info";
}
.content-img {
  grid-area: img;
}
.content-not-selected {
  filter: opacity(30%);
}
.content-title {
  grid-area: title;
  height: 2.8em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.content-info {
    grid-area: info;
}
.content-info span {
  display: inline-block; 
  width: 75px;
}

svg {
  width: 24px;
  height: 24px;
  fill: rgba(0, 111, 135, 1);
}
</style>