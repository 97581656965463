import { createWebHistory, createRouter } from "vue-router"

import HomePage from "@/views/HomePage.vue"
import CatalogContents from "@/views/CatalogContents.vue"
import CatalogRoutes from "@/views/CatalogRoutes.vue"
import CatalogCourses from "@/views/CatalogCourses.vue"
import ActiveChallenge from "@/views/ActiveChallenge.vue"
import GradeBook from "@/views/GradeBook.vue"
import PlayActivity from "@/views/PlayActivity.vue"
import NoLicense from "@/views/NoLicense.vue"
import NoEdit from "@/views/NoEdit.vue"

const routes = [

  // Start screen.
  // Allows the selection of the catalog.
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },

  // Catalog of contents.
  // Allows to search or edit an activity by managing individual contents.
  {
    path: "/catalog-contents",
    name: "CatalogContents",
    component: CatalogContents,
  },

  // Catalog of routes.
  // Allows to search predefined Smile and Learn learning routes.
  {
    path: "/catalog-routes",
    name: "CatalogRoutes",
    component: CatalogRoutes,
  },

  // Catalog of courses.
  // Allow to search entire courses.
  {
    path: "/catalog-courses",
    name: "CatalogCourses",
    component: CatalogCourses,
  },

  // Active challenge.
  // Only shown if a challenge is currently active.
  {
    path: "/active-challenge",
    name: "ActiveChallenge",
    component: ActiveChallenge,
  },

  // Gradebook.
  // Displays the performance of a student in an activity.
  {
    path: "/gradebook",
    name: "GradeBook",
    component: GradeBook,
  },

  // Play screen.
  // Displays an activity for a student to play.
  {
    path: "/play",
    name: "PlayActivity",
    component: PlayActivity,
  },

  // No license screen.
  // Displayed when the user license is not active.
  {
    path: "/no-license",
    name: "NoLicense",
    component: NoLicense,
  },

  // No edit screen.
  // Displayed when the content cannot be edited.
  {
    path: "/no-edit",
    name: "NoEdit",
    component: NoEdit,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;