<template>

    <!-- Container -->
    <div class="sl-header">

        <!-- Header navbar -->
        <div class="navbar-fixed-top inline-block">
            <img class="navbar-brand" alt="Smile and Learn" src="../assets/logo_sl.png">           
            <p v-if="!welcome && showButton" class="go-back-button"  @click="clear">
                Volver al Inicio
            </p>   
        </div>

        <!-- Welcome screen -->
        <div v-if="welcome" class="big-header">
            <img alt="Smile and Learn" src="../assets/logo.png">
            <h2>Bienvenido a Smile and Learn para Moodle</h2>
            <p class="bg-lineas-diagonales">
                Visítanos en <a href="https://smileandlearn.com/" target="_blank" rel="noopener">smileandlearn.com</a>.
            </p>
        </div>
        <div v-else class="no-header"></div>
    </div>
</template>

<script>

// Methods
import { 
  updateActivityContents,
} from '@/lib/functions'

export default {

    
    props: {
        // Whether this is the welcome screen.
        // In such case, hides the navbar button.
        welcome: Boolean,

        // Whether the button should be shown.
        showButton: Boolean,

        // API key (client token).
        apiKey: String,

        // API token.
        token: String,

        // Activity id.
        activityId: Number,

        // Language.
        lang: String,
    },

    methods: {
        /**
         * Clears the page and goes back to the home.
         */
        clear() {
            
            // Removes the stored contents.
            if (this.activityId) updateActivityContents(this.apiKey, this.activityId, this.lang, [])

            // Clears the name and description.
            parent.postMessage({
                type: 'smile.lti.info',
                name: '',
                description: '',
            }, '*')

            // Goes back to the home.
            this.$router.push({ name: 'HomePage', query: {'apiKey': this.apiKey, 'token': this.token, 'activityId': this.activityId}})
        }
    }
}
</script>