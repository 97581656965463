<template>

  <!-- -->
  <div class="boxed grade-detail" @click="fixAccordionHeight">
    <!-- Content name and icon -->
    <span @click="selected = !selected" class="subtitle-item inline-block pointer">
      <svg v-if="selected" class="chev-up" width="24" height="24" style="margin-right:10px; fill: rgba(0, 111, 135, 1);">
        <path d="m12 6.879-7.061 7.06 2.122 2.122L12 11.121l4.939 4.94 2.122-2.122z"></path>
      </svg>
      <svg v-if="!selected" class="chev-down" width="24" height="24" style="margin-right:10px; fill: rgba(0, 111, 135, 1);">
        <path d="M16.939 7.939 12 12.879l-4.939-4.94-2.122 2.122L12 17.121l7.061-7.06z"></path>
      </svg>
      <img width="40" :src="content.icon"  :alt="content.title" :title="content.title" />
      <span style="margin-left:10px;font-weight: 600; color: #3F87C7;">{{ content.title }}</span>
    </span>

    <span class="icon-set">
      <!-- Completed sign -->
      <svg v-if="grades.completed" class="green" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
      </svg> 
      
      <!-- Play button -->
      <svg @click="$emit('manage-drawer', content.id)" class="pointer">
          <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
        </svg>           
    </span>
  </div>

  <!-- Grade details -->
  <div v-if="selected" class="boxed collapsible">
    <div class="attempt-data inline-block" v-for="attempt in grades.grades" :key="attempt.feedback_id">
      <div style="height: 24px;">
        <!-- Date & time-->
        <span style="display: inline-flex; width: 180px; margin-right: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: .5em" width="24" height="24" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 11h6v1h-7v-9h1v8z" />
          </svg>
          <span style="vertical-align: top;">{{ formatDate(attempt.timestamp) }}</span>
        </span>

        <!-- Type-->
        <span style="display: inline-flex; width: 120px; margin-right: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: .5em" width="24" height="24" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd">
            
            <!-- Game -->
            <path  v-if="getType(attempt.type) == 'game'" d="M6 3h2v2H6zm2 16h3v2H8zm8-16h2v2h-2zm-3 16h3v2h-3zm7-8V9h-2V7h-2V5h-2v2h-4V5H8v2H6v2H4v2H2v8h2v-4h2v4h2v-3h8v3h2v-4h2v4h2v-8zm-10 1H8V9h2zm6 0h-2V9h2z"></path>

            <!-- Tale -->
            <path v-if="getType(attempt.type) == 'tale'" d="M19 2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.806 5 19c0-.101.009-.191.024-.273.112-.576.584-.717.988-.727H21V4a2 2 0 0 0-2-2zm0 9-2-1-2 1V4h4v7z"></path>

            <!-- Quiz -->
            <path v-if="getType(attempt.type) == 'quiz'" d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2zM5 5h2v2h10V5h2v15H5V5z"></path>
            <path v-if="getType(attempt.type) == 'quiz'" d="m11 13.586-1.793-1.793-1.414 1.414L11 16.414l5.207-5.207-1.414-1.414z"></path>

            <!-- Theory -->
            <path v-if="getType(attempt.type) == 'theory'" d="M6.012 18H21V4a2 2 0 0 0-2-2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.805 5 19s.55-.988 1.012-1zM8 6h9v2H8V6z"></path>

            <!-- Video -->
            <path v-if="getType(attempt.type) == 'video'" d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7z"></path>
            
            <!-- Audiobook -->
            <circle v-if="getType(attempt.type) == 'audiobook'" cx="12.01" cy="12" r="2"></circle>
            <path v-if="getType(attempt.type) == 'audiobook'" d="M11.01 22h2l.5-7h-3l.5 7z"></path>
            <path v-if="getType(attempt.type) == 'audiobook'" d="M12 2a10 10 0 0 0-2.45 19.68l-.15-2.12a8 8 0 1 1 5.21 0l-.15 2.12A10 10 0 0 0 12 2z"></path>
            <path v-if="getType(attempt.type) == 'audiobook'" d="M15.32 9.61a3.44 3.44 0 0 1 .37.68 3.83 3.83 0 0 1 .23.75 3.57 3.57 0 0 1 .09.8 3.66 3.66 0 0 1-.09.81 3.83 3.83 0 0 1-.23.75 3.44 3.44 0 0 1-.37.68 4.7 4.7 0 0 1-.35.43l-.19 2.62a5.33 5.33 0 0 0 .58-.31A5.86 5.86 0 0 0 17 15.2a5.57 5.57 0 0 0 .55-1 5.89 5.89 0 0 0 .35-1.13 6.06 6.06 0 0 0 .1-1.23 6.22 6.22 0 0 0-.13-1.21A6.09 6.09 0 0 0 17 8.49a6.29 6.29 0 0 0-.73-.89 5.67 5.67 0 0 0-.89-.73 6.3 6.3 0 0 0-1-.56A6.17 6.17 0 0 0 13.21 6a6.11 6.11 0 0 0-2.41 0 5.51 5.51 0 0 0-1.13.36 5.57 5.57 0 0 0-1 .55 5.67 5.67 0 0 0-.89.73 6.29 6.29 0 0 0-.78.85 6.09 6.09 0 0 0-.9 2.14 6.21 6.21 0 0 0-.1 1.21 6.06 6.06 0 0 0 .12 1.21 5.89 5.89 0 0 0 .35 1.13 5.57 5.57 0 0 0 .55 1 6.24 6.24 0 0 0 1.62 1.62 5.33 5.33 0 0 0 .58.31L9 14.51a4.7 4.7 0 0 1-.35-.43 3.44 3.44 0 0 1-.37-.68 3.83 3.83 0 0 1-.23-.75 3.65 3.65 0 0 1-.05-.81 3.56 3.56 0 0 1 .08-.8 3.83 3.83 0 0 1 .23-.75 3.44 3.44 0 0 1 .37-.68 4 4 0 0 1 .5-.61 3.87 3.87 0 0 1 .59-.48 3.44 3.44 0 0 1 .68-.37 3.86 3.86 0 0 1 .75-.24 4.36 4.36 0 0 1 1.61 0 3.86 3.86 0 0 1 .75.24 3.58 3.58 0 0 1 1.27.85 3.49 3.49 0 0 1 .49.61z"></path>

            <!-- PDF -->
            <path v-if="getType(attempt.type) == 'pdf'" d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z"></path>
            <path v-if="getType(attempt.type) == 'pdf'" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"></path>
          </svg>
          <span style="vertical-align: top;">{{ formatType(attempt.type) }}</span>
        </span>

        <!-- Duration -->
        <span style="display: inline-flex; width: 160px; margin-right: 1em;">
          <svg  v-if="getType(attempt.type) != 'pdf'" xmlns="http://www.w3.org/2000/svg" style="margin-right: .5em" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z"/>       
          </svg>
          <span  v-if="getType(attempt.type) != 'pdf'" style="vertical-align: top;">({{ Math.floor(attempt.time / 60) }} min. {{ Math.floor(attempt.time % 60) }} seg.)</span>
        </span>

        <!-- Level -->
        <span v-if="attempt.level > 0" style="display: inline-flex; width: 120px; margin-right: 1em;">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: .5em" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M5 19h-4v-4h4v4zm6 0h-4v-8h4v8zm6 0h-4v-13h4v13zm6 0h-4v-19h4v19zm1 2h-24v2h24v-2z" />         
          </svg>
          <span style="vertical-align: top;">{{ formatLevel(attempt.level) }}</span>
        </span>

        <!-- Grade -->
        <span v-if="attempt.total > 0" style="display: inline-flex; width: 100px;" class="grade">
          <svg xmlns="http://www.w3.org/2000/svg" class="blue" style="margin-right: .5em" width="24" height="24" viewBox="0 0 24 24" >
            <path d="M18.926 8.277c.051.147.074.297.074.445 0 .449-.222.883-.615 1.156-1.256.87-1.09.651-1.562 2.067-.198.591-.77.99-1.414.99h-.004c-1.549-.005-1.279-.088-2.528.789-.262.184-.569.276-.877.276s-.615-.092-.876-.275c-1.249-.878-.98-.794-2.528-.789h-.004c-.645 0-1.216-.399-1.413-.99-.473-1.417-.311-1.198-1.562-2.067-.395-.274-.617-.708-.617-1.157 0-.148.024-.298.074-.444.483-1.411.484-1.139 0-2.555-.05-.147-.074-.297-.074-.445 0-.45.222-.883.616-1.157 1.251-.868 1.089-.648 1.562-2.067.197-.591.769-.99 1.413-.99h.004c1.545.005 1.271.095 2.528-.79.262-.183.569-.274.877-.274s.615.091.876.274c1.249.878.98.795 2.528.79h.004c.645 0 1.216.399 1.414.99.473 1.416.307 1.197 1.562 2.067.394.273.616.707.616 1.156 0 .148-.023.299-.074.445-.483 1.41-.485 1.139 0 2.555zm-3.426-2.798l-1.084-1.083-3.291 3.374-1.541-1.462-1.084 1.084 2.625 2.545 4.375-4.458zm-6.135 9.452c-.766 0-1.371-.074-1.873-.213-.308 3.068-1.359 5.37-3.492 7.592.854.107 1.95-.094 2.833-.56.317.636.65 1.43.767 2.25 2.009-2.299 3.266-5.054 3.734-8.071-.943-.181-1.234-.496-1.969-.998zm5.27 0c-.737.507-1.043.82-1.968.998.47 3.017 1.726 5.772 3.733 8.071.116-.82.449-1.614.767-2.25.883.465 1.979.667 2.833.56-2.13-2.219-3.168-4.531-3.479-7.595-.503.141-1.112.216-1.886.216z" />          </svg>
          <span style="vertical-align: top;">{{ attempt.score }} / {{ attempt.total }}</span>
        </span>
      </div>

      <!-- Completion status -->
      <span class="icon-completed" style="height: 24px;">
        <svg v-if="attempt.completed" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="width: 24px;">
          <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
        </svg>   
      </span>
    </div>
  </div>

</template>

<script>
import { FEEDBACK_TYPES, LEVEL_NAMES, TYPE_NAMES } from '@/lib/constants'

export default {

  // Input properties.
  props:{

    // The info of the content.
    content: Object,

    // The grades for the content.
    grades: Object,
  },

  // Emits.
  emits: [
    'manageDrawer',
  ],

  // Data.
  data () {
    return {
      selected: false
    }
  },

  // Methods.
  methods: {

    /**
     * Formats the level for printing.
     */
    formatLevel (levelId) {
      return Object.values(LEVEL_NAMES)[levelId-1];
    },

    /**
     * Retrieves the feedback type.
     */
    getType (typeId) {
      return FEEDBACK_TYPES[typeId];
    },

    /**
     * Formats the feedback type for printing.
     */
    formatType (typeId) {
      return TYPE_NAMES[FEEDBACK_TYPES[typeId]];
    },

    /**
     *  Formats the date for printing.
     */
    formatDate (timestamp) {
      const date = new Date(timestamp)
      return `${date.toLocaleDateString("es-ES")} ${date.toLocaleTimeString("es-ES")}`
    },

    /**
     * Fixes the issue with the accordion height.
     */
     fixAccordionHeight () {
      document.querySelector('.vcp__body').style = ''
    },
  }
}
</script>

<style scoped>
.boxed {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
}
.grade-detail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
} 
.icon-set {
  width: 60px;
}
.icon-set svg {
  width: 30px;
  height: 30px;
  fill: rgba(0, 111, 135, 1);
}
.icon-completed svg {
  fill: rgba(0, 111, 135, 1);
}
.pointer {
  cursor: pointer;
}
.green {
  fill: green !important;
}
.collapsible {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
}
.attempt-data{
  border-bottom: 1px solid lightgrey;
  padding: 10px 0;
}
.attempt-data:last-child {
  border-bottom: none;
}
.grade {
  color: #3F87C7;
  font-weight: 700;
}
.blue {
  fill: #3F87C7;
}
.subtitle-item {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>