<template>

  <!-- Container -->
  <div class="inline-block" style="height: 60px;">

    <!-- Route entry -->
    <span class="route pointer" @click="$emit('toggle-select-course', item)">

      <!-- Checkbox -->
      <span class="checkbox">
        <svg 
          v-if="isSelected" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
        </svg>
        <svg 
          v-else 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 2C6.486 2 2 6.486 2 12c.001 5.515 4.487 10.001 10 10.001 5.514 0 10-4.486 10.001-10.001 0-5.514-4.486-10-10.001-10zm0 18.001c-4.41 0-7.999-3.589-8-8.001 0-4.411 3.589-8 8-8 4.412 0 8.001 3.589 8.001 8-.001 4.412-3.59 8.001-8.001 8.001z"></path>
        </svg>
      </span>

      <!-- Route name -->
      <span class="name"> {{ item }} </span>
    </span>
    
    <!-- Info button -->
    <span v-if="showInfo" class="icon-set">
      <svg 
        class="pointer"
        @click="$emit('manage-drawer', item)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
      </svg>
    </span>
  </div>

</template>

<script>
export default {

  // Input properties.
  props: {

    // The content (item).
    item: {
      type: String,
      required: true,
    },

    // Whether the item is selected.
    isSelected: {
      type: Boolean,
      required: true,
    },

    // Whether to show the customize button.
    showCustomize: Boolean,

    // Whether to show the info button.
    showInfo: Boolean,
  },

  // Emits.
  emits: [
    'toggle-select-course',
    'customize-course',
    'manage-drawer',
  ],
}
</script>

<style scoped>

.route {
  display: flex; 
  align-items: center;
}
.checkbox {
  min-width: 40px;
}
.checkbox svg {
  vertical-align: middle;
}
.name {
  font-weight: 500;
}
svg {
  width: 24px;
  height: 24px;
  fill: rgba(0, 111, 135, 1);
}
</style>