<template>

<!-- Main container (when everything is ready) -->
<div v-if="loaded">

  <!-- Smile and Learn header -->
  <smile-header 
    :welcome="false"
    :show-button="true"
    :api-key="apiKey"
    :token="token"
    :activity-id="activityId"
    :lang="lang"
  />

  <!-- Heading message -->
  <div class="catalog-message">
    <p>Utiliza esta opción para buscar y seleccionar una secuencia didáctica. Las secuencias didácticas son itinerarios de objetos de aprendizaje diseñados por el equipo pedagógico de Smile and Learn que contienen diversas actividades en torno a un tema concreto.</p>
  </div>

  <!-- Search module -->
  <search-engine
    id="sl-search"
    :lang="lang"
    :searchPaths="true"
    :editing="false"
    :searching="searching"
    @change-lang="changeLang"
    @search="search" 
  />

  <!-- Routes panel (only when some routes are to be displayed) -->
  <div id="sl-routes" class="sl-routes" v-if="resultRoutes.length > 0 || selectedRoute !== null">
    <item-route
      v-for="item in resultRoutes" 
      :key="item.id" 
      :item="item" 
      :is-selected="isSelected(item.id)"
      @toggle-select-route="clickItem"
      @customize-route="customizeRoute"
      @manage-drawer="manageDrawer"
    />
  </div>

  <!-- Routes information panel -->
  <drawer-route
    :visible="drawerShow"
    :token="token"
    :route-id="drawerRoute?.id"
    :lang="lang"
    @manage-drawer="manageDrawer"
  />
</div>

<!-- Alternative container when data is loading -->
<div v-else>
  <div class="unloaded">
    <span class="bg-lineas-diagonales">
      Cargando...
    </span>
  </div>
</div>

</template>

<script>

// Methods
import { 
  validateApiKey, 
  updateActivityContents,
  searchRoutes,
  getRouteDetails,
} from '@/lib/functions'

// Graphic components
import DrawerRoute from '@/components/DrawerRoute.vue'
import ItemRoute from '@/components/ItemRoute.vue'
import SearchEngine from '@/components/SearchEngine.vue'
import SmileHeader from '@/components/SmileHeader.vue'

// Assets
import '@/assets/css/styles.css';


export default {

  // Component name.
  name: 'CatalogRoutes',

  // Components.
  components: {
    DrawerRoute,
    ItemRoute,
    SearchEngine,
    SmileHeader,
  },

  // Data.
  data () {
    return {
      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Activity id.
      activityId: null,

      // Whether page is fully loaded.
      loaded: false,

      // Chosen language.
      lang: 'es',

      // Whether search operation is taking place.
      searching: false,

      // Routes resulted by searching.
      resultRoutes: [],

      // Route selected by the user.
      selectedRoute: null,

      // Whether drawer must be shown.
      drawerShow: false,

      // Route to be displayed in the drawer.
      drawerRoute: null,
    }
  },

  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    this.apiKey = this.$route.query.apiKey
    this.token = this.$route.query.token
    this.activityId = parseInt(this.$route.query.activityId)

    // Handle scroll event.
    window.onscroll = this.handleScroll

    // Validates the API key.
    if (!(await validateApiKey(this.apiKey))) this.$router.push('/no-license')

    // Site is finally loaded.
    this.loaded = true
  },

  // Code executed for handling events when the page is unloaded.
  unmounted () {
    window.onscroll = null
  },

  // Methods.
  methods: {
    
    /**
     * Changes the language of the activity.
     */
    changeLang (lang) {
      this.lang = lang
    },

    /**
     * Whether a route has already been selected.
     */
    isSelected (route_id) {
      return this.selectedRoute?.id == route_id
    },

    /**
     * Retrieve contents IDs from a route.
     */
    getContentsIds (route) {
      return route.contents.map( c => c.id )
    },

    /**
     * Performs the search event.
     */
    async search (data) {
      this.resultRoutes = []
      this.unselectRoute()
      this.searching = true
      this.resultRoutes = await searchRoutes(this.token, data)
      this.searching = false
    },

    /**
     * Select a route from the search results.
     */
    async selectRoute (route) {
      this.selectedRoute = await getRouteDetails(this.token, this.lang, route.id)
      updateActivityContents(this.apiKey, this.activityId, this.lang, this.getContentsIds(this.selectedRoute))
      this.messageInfo(this.selectedRoute.name, this.selectedRoute.description)
    },

    /**
     * Unselect a previously selected route.
     */
    unselectRoute () {
      this.selectedRoute = null
      updateActivityContents(this.apiKey, this.activityId, this.lang, [])
      this.messageInfo('', '')
    },

    /**
     * Event executed when a route from the search results is clicked.
     */
    clickItem (route) {
      if (!this.isSelected(route.id)) this.selectRoute(route)
      else this.unselectRoute();
    },

    /**
     * Redirects to the contents customization view.
     */
    customizeRoute () {
      this.$router.push({
        name: 'CatalogContents', 
        query: {
          'apiKey': this.apiKey,
          'token': this.token, 
          'activityId': this.activityId, 
          'customizing': true,
        }
      })
    },

    /**
     * Messages the info to the parent (e.g. Moodle).
     */
    messageInfo (name, desc) {
      parent.postMessage({
        type: 'smile.lti.info',
        name: name,
        description: desc,
      }, '*')
    },

    /**
     * Shows or hides the drawer.
     */
    manageDrawer (route) {
      this.drawerRoute = route
      this.drawerShow = route !== null
    },

    /**
     * Handles the scroll of the site.
     */
    handleScroll () {      
      var header = document.getElementById("sl-search")
      var routes = document.getElementById("sl-routes")
      let height = header.offsetHeight
      if (routes) {
        if (window.pageYOffset > 278) {
          header.classList.add("sticky")
          routes.style.marginTop = height + 'px'
        } else {
          header.classList.remove("sticky")
          routes.style.marginTop = '0px'
        }
      }
    }
  }
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f87c7;
}
.catalog-message {
  max-width: 800px; 
  margin: 0 auto; 
  text-align: center;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 60px 0;
}
.unloaded {
  text-align: center; 
  margin: 20px;
}
.unloaded span {
  color: white; 
  padding: 10px 20px; 
  font-weight: 600; 
  border-radius: 10px;
}
</style>