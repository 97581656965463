import CryptoJS from 'crypto-js';

import { 
    KEY,
} from '@/lib/constants'

/**
 * Performs Base64 URL encoding.
 */
const encodeURL = (data) => data.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', ',')

/**
 * Encrypts a value using Base64.
 */
export const encodeB64 = (data, encode = true) => {
    const word = CryptoJS.enc.Utf8.parse(data)
    const b64 = CryptoJS.enc.Base64.stringify(word)
    return encode ? encodeURL(b64) : b64
}

/**
 * Encrypts a value using AES-256.
 */
export const encrypt = (data, encode = true) => {
    const iv  = CryptoJS.enc.Utf8.parse("")
    const key = CryptoJS.enc.Utf8.parse(KEY)
    const enc = CryptoJS.AES.encrypt(data.toString(), key, {
        iv: iv, 
        mode: CryptoJS.mode.ECB, 
        padding: CryptoJS.pad.Pkcs7
    })
    const b64 = CryptoJS.enc.Base64.stringify(enc.ciphertext)
    return encode ? encodeURL(b64) : b64
}