/**
 * ENDPOINTS | CONTENTS
 */

// Contents host.
const HOST_CONTENTS = process.env.VUE_APP_HOST_CONTENTS

// Search WebGL contents.
export const URL_WEBGL_CONTENTS = `${HOST_CONTENTS}/contents/webgl`

// Search WebGL routes.
export const URL_WEBGL_ROUTES = `${HOST_CONTENTS}/routes/webgl`

// Search WebGL courses.
export const URL_WEBGL_COURSES = `${HOST_CONTENTS}/courses/webgl`

// Get content details.
export const URL_WEBGL_CONTENT_DETAILS = `${HOST_CONTENTS}/contents/webgl/details/{id}`

// Get route details.
export const URL_WEBGL_ROUTE_DETAILS = `${HOST_CONTENTS}/routes/webgl/details/{id}`

// Get active challenges.
export const URL_WEBGL_CHALLENGES = `${HOST_CONTENTS}/challenges`

// Get challenge details.
export const URL_WEBGL_CHALLENGE_DETAILS = `${HOST_CONTENTS}/challenges/{id}`

/**
 * ENDPOINTS | LTI
 */

// LTI host.
const HOST_LTI = process.env.VUE_APP_HOST_LTI


// Check license.
export const URL_LTI_CHECK_LICENSE = `${HOST_LTI}/clients/active`

// Activity registration.
export const URL_LTI_REGISTER_ACTIVITY = `${HOST_LTI}/activities`

// Activity update.
export const URL_LTI_UPDATE_ACTIVITY = `${HOST_LTI}/activities/{id}`

// Activity details.
export const URL_LTI_DETAILS_ACTIVITY = `${HOST_LTI}/activities/{id}`

// Feedback
export const URL_LTI_FEEDBACK = `${HOST_LTI}/feedback/store`

// Gradebook grades.
export const URL_LTI_GET_GRADES = `${HOST_LTI}/gradebook/grades`

// Gradebook completion.
export const URL_LTI_GET_COMPLETION = `${HOST_LTI}/gradebook/completed`
