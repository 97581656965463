/**
 * LANGUAGES
 */

// Whether the app is running in local
export const IS_LOCAL = location.host.includes('localhost')

// Key
export const KEY = 'A?D(G+KbPeShVkYp3s6v9y$B&E)H@McQ'

// Languages names
export const LANG_NAMES = {
    'es': 'español',
    'en': 'inglés',
    'fr': 'francés',
    'pt': 'portugués',
    'it': 'italiano',
    'ca': 'catalán',
    'la': 'español de Latinoamérica',
}

// Languages IDs
export const LANG_IDS = {
    'es': 0,
    'en': 1,
    'fr': 2,
    'pt': 3,
    'it': 4,
    'ca': 5,
    'tr': 6,
    'la': 7,
}

// Content types
export const FEEDBACK_TYPES = [
    'game',
    'tale',
    'quiz',
    'theory',
    'video',
    'audiobook',
    'pdf',
]

// Content types
export const TYPE_NAMES = {
    'tale': 'Cuento',
    'game': 'Juego',
    'theory': 'Teoría',
    'escape_room': 'Escape Room',
    'vocabulary': 'Vocabulario',
    'reading': 'Lectura',
    'activity': 'Actividad',
    'audiobook': 'Audiolibro',
    'ar': 'Realidad aumentada',
    'quiz': 'Quiz',
    'video': 'Vídeo',
    'evaluation': 'Evaluación',
    'pdf': 'PDF',
    'customquiz': 'Quiz',
    'customaudiobook': 'Audiolibro',
}

// Levels
export const LEVEL_NAMES = {
    'apprentice': 'Aprendiz',
    'intermediate': 'Intermedio',
    'advanced': 'Avanzado',
    'master': 'Maestro',
}

// Minimum video duration for completion
export const MIN_VIDEO_DURATION = 0.8