<template>

  <div class="info-container">

    <!-- Activity and student info -->
    <div class="info">
      <div class="info-item">
          <span class="sl-label">Curso </span>
          <input :value="courseName" disabled type="text" class="sl-input" />
      </div>
      <div class="info-item">
          <span class="sl-label">Actividad </span>
          <input :value="activityName" disabled type="text" class="sl-input" />
      </div>
      <div class="info-item">
          <span class="sl-label">Estudiante </span>
          <input :value="studentName" disabled type="text" class="sl-input" />
      </div>
    </div>

    <!-- Grade and passing status -->
    <div :class="[passed == null ? 'passed-unknown' : passed ? 'have-passed' : 'have-failed', 'box']" >
      <div class="vertical-center"><span>{{ formatGrade(grade) }}</span></div>
    </div>

  </div>

  <!-- Details header-->
  <div class="sl-contents-header ">
    <h3>
      Detalles
    </h3>
  </div>

</template>

<script>
export default {

  // Input properties.
  props:{

    // Name of the course.
    courseName: {
      type: String,
      required: true,
    },

    // Name of the activity.
    activityName: {
      type: String,
      required: true,
    },

    // Name of the student.
    studentName: {
      type: String,
      required: true
    },

    // Grade.
    grade: {
      type: [Number, null],
      required: true,
    },

    // Whether the student passed.
    passed: {
      type: [Boolean, null],
      required: true,
    },
  },

  // Methods.
  methods: {

    /**
     * Formats a numeric grade.
     */
    formatGrade (grade) {
      return grade ? grade.toFixed(1) : '--'
    },
  },
}
</script>

<style scoped>
  .info-container {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .info {
    flex-grow: 2;
  }
  .info-item {
    display: flex;
    align-items: center;
  }

  .sl-label {
    width: 80px;
  }

  .box {
    margin:25px;
    width:150px;
    height:150px;
    background:#fff;
    border: solid;
    float:left; 
    border-width: 8px 3px 3px 8px;
    border-radius: 50%;
    transform: rotate(2deg);
  }
  .passed-unknown {
    color: #88BEEF;
    border-color: #88BEEF;
  }
  .have-passed {
    color: #10b765;
    border-color: #10b765;
  }
  .have-failed {
    color: tomato;
    border-color: tomato;
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-size:60px;
    font-weight: 700;
  }
</style>