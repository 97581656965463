import { 
    URL_LTI_CHECK_LICENSE, 
    URL_LTI_REGISTER_ACTIVITY,
    URL_LTI_DETAILS_ACTIVITY,
    URL_LTI_UPDATE_ACTIVITY,
    URL_LTI_FEEDBACK,
    URL_LTI_GET_GRADES,
    URL_LTI_GET_COMPLETION,
    URL_WEBGL_CONTENTS,
    URL_WEBGL_COURSES,
    URL_WEBGL_ROUTES,
    URL_WEBGL_CONTENT_DETAILS,
    URL_WEBGL_ROUTE_DETAILS,
    URL_WEBGL_CHALLENGES,
    URL_WEBGL_CHALLENGE_DETAILS,
} from '@/api/routes'

import axios from 'axios'

/**
 * Validates the API key.
 * If invalid, redirects the client to the "no license" site.
 */
export const validateApiKey = (apiKey) =>
    axios.get(URL_LTI_CHECK_LICENSE, {
        params: {
            api_key: apiKey,
        },
    }).then((r) => r.data.client).catch(() => null)

/**
 * Registers a new activity.
 */
export const registerNewActivity = (apiKey) =>
    axios.post(URL_LTI_REGISTER_ACTIVITY, {
        api_key: apiKey
    }).then((r) => r.data.activity_id).catch(() => null)

/** 
 * Loads the details of an activity, which are the selected lang and contents.
 */
export const getActivity = (apiKey, activityId, studentId = null) =>
    axios.get(URL_LTI_DETAILS_ACTIVITY.replace('{id}', activityId), {
      params: {
        api_key: apiKey,
        student_id: studentId,
      }
    }).then((r) => r.data.activity)

/** 
 * Updates the contents in the database.
 */
export const updateActivityContents = (apiKey, activityId, lang, contents) =>
    axios.put(URL_LTI_UPDATE_ACTIVITY.replace('{id}', activityId), {
      api_key: apiKey,
      lang: lang,
      contents: contents,
    })

/**
 * Sends a feedback call.
 */
 export const feedback = (data) => 
    axios.get(URL_LTI_FEEDBACK, {
        params: data
    }).then((r) => r.data)

/**
 * Sends a feedback call.
 */
export const getGrades = (apiKey, activityId, studentId) => 
    axios.get(URL_LTI_GET_GRADES, {
        params: {
            api_key: apiKey,
            activity_id: activityId,
            student_id: studentId, 
        }
    }).then((r) => r.data)

/**
 * Sends a feedback call.
 */
export const getCompletion = (apiKey, activityId, studentId) => 
    axios.get(URL_LTI_GET_COMPLETION, {
        params: {
            api_key: apiKey,
            activity_id: activityId,
            student_id: studentId, 
        }
    }).then((r) => r.data)

/**
 * Searches for Smile and Learn contents available in WebGL format.
 */
export const searchContents = (token, data) =>
    axios.post(URL_WEBGL_CONTENTS, {
        token: token,
        ...data,
    }).then((r) => r.data)

/**
 * Searches for Smile and Learn routes.
 */
export const searchRoutes = (token, data) =>
    axios.post(URL_WEBGL_ROUTES, {
        token: token,
        ...data,
    }).then((r) => r.data)

/**
 * Searches for a Smile and Learn course.
 */
export const searchCourse = (token, data) =>
    axios.post(URL_WEBGL_COURSES, {
        token: token,
        ...data,
    }).then((r) => r.data)

/**
 * Gets the details of a WebGL content.
 */
export const getContentDetails = (token, lang, contentId) => 
    axios.get(URL_WEBGL_CONTENT_DETAILS.replace('{id}', contentId), {
        params: {
            token: token,
            lang: lang,
        }
    }).then((r) => r.data)

/**
 * Gets the details of a WebGL route.
 */
export const getRouteDetails = (token, lang, routeId) => 
    axios.get(URL_WEBGL_ROUTE_DETAILS.replace('{id}', routeId), {
        params: {
            token: token,
            lang: lang,
        }
    }).then((r) => r.data)

/**
 * Gets active challenges.
 */
export const getActiveChallenges = (token) =>
    axios.get(URL_WEBGL_CHALLENGES, {
        params: {
            token: token,
        }
    }).then((r) => r.data)

/**
 * Gets the details of a challenge.
 */
export const getChallengeDetails = (token, challengeId, lang) => 
    axios.get(URL_WEBGL_CHALLENGE_DETAILS.replace('{id}', challengeId), {
        params: {
            token: token,
            lang: lang,
        }
    }).then((r) => r.data)