<template>
  <div class="lang-wizard-container">
        <div class="lang-wizard">
          <h3>Selecciona el idioma para la actividad:</h3>
          <select v-model="lang" name="lang" id="lang" class="sl-input">
            <option value="es">Español</option>
            <option value="en">Inglés</option>
            <option value="fr">Francés</option>
            <!--<option value="pt">Portugués</option>-->
            <!--<option value="it">Italiano</option>-->
            <!--<option value="ca">Catalán</option>-->
            <!--<option value="la">Español LatAm</option>-->
          </select>
          <p class="continue-button"  @click="$emit('set-lang', lang)">Continuar</p> 
        </div>        
  </div>
</template>

<script>
export default {
  // Component name.
  name: "LanguageWizard",

  // Data.
  data () {
    return {
      // Selected language.
      lang: 'es',
    }
  },

  // Emits.
  emits: [
    'set-lang',
  ],
}
</script>

<style scoped>
.lang-wizard-container {
  text-align: center; 
  margin-top: 20px;
}
.lang-wizard {
  background-color: white; 
  max-width: 600px; 
  margin: auto; 
  padding: 1em 0;
  border-radius: 10px;
} 
.sl-input {
  width: 230px;
}
</style>