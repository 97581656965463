<template>
  <div class="list-group-item inline-block" >
                                
      <span class="subtitle-item inline-block">
        <svg class="move handle" width="24" height="24" style="margin-right:10px; fill: rgba(0, 111, 135, 1);transform: ;msFilter:;"><path d="m7 17 5 5 5-5h-4V7h4l-5-5-5 5h4v10z"></path></svg>
        {{ item.title }} – {{ typeName }}
      </span>
      <span>
        <span style="display: inline-block; width: 75px;">
          <svg @click="$emit('manage-drawer', item.id)" width="24" height="24" 
            class="pointer" style="margin-right:10px;fill: rgba(0, 111, 135, 1);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg>
          
          <svg class="pointer" @click="$emit('toggle-select-content', item)" width="24" height="24" style="fill: rgba(255, 51, 0, 1);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z"></path></svg>
        </span>
      </span>
  </div>
</template>

<script>

// Constants
import { TYPE_NAMES } from '@/lib/constants'

export default {

  // Input properties.
  props: {
    item: Object
  },

  // Emits.
  emits: [
    'toggleSelectContent',
    'manageDrawer',
  ],

  // Computed properties.
  computed: {

    /**
     * Properly formatted content type name
     */
    typeName () {
      return this.item ? TYPE_NAMES[this.item.type] : ''
    }
  },

}
</script>

<style>
.list-group-item .move {
  cursor: move;
}
.inline-block {
    /* color: #3a81a5; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subtitle-item{
    overflow: hidden;
    text-overflow: ellipsis;


    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>