<template>

  <!-- Main container (when everything is ready) -->
  <div v-if="loaded">
  
    <!-- Smile and Learn header -->
    <smile-header 
      :welcome="false"
      :show-button="true"
      :api-key="apiKey"
      :token="token"
      :activity-id="activityId"
      :lang="lang"
    />
  
    <!-- Heading message -->
    <div class="catalog-message">
      <p>Utiliza esta opción para seleccionar programas formativos. Estos programas están diseñados por el equipo pedagógico de Smile and Learn y contienen todos los materiales curriculares para una materia.</p>
    </div>

    <div class="sl-courses-header sl-courses-header-grid">
      <h3>
        Cursos
      </h3>
      <h3>
        Materias
      </h3>
    </div>

    <!-- Courses panel -->
    <div id="sl-courses" class="sl-courses">

      <!-- Courses -->
      <div class="item item-course">
        <item-course
          v-for="course in courses" 
          :key="course" 
          :item="course" 
          :is-selected="isSelectedCourse(course)"
          @toggle-select-course="clickCourse"
        />
      </div>

      <!-- Subjects -->
      <div class="item item-subject" v-if="selectedCourse">
        <item-course
          v-for="subject in getSubjects()" 
          :key="subject" 
          :item="subject" 
          :is-selected="isSelectedSubject(subject)"
          show-customize
          show-info
          @toggle-select-course="clickSubject"
          @customize-course="customizeCourse"
          @manage-drawer="manageDrawer"
        />
      </div>
    </div>

    <!-- Routes information panel -->
    <drawer-route
      :visible="drawerShow"
      :token="token"
      :course="drawerCourse"
      :lang="drawerLang"
      @manage-drawer="manageDrawer"
    />
  </div>

  </template>
  
  <script>
  
  // Methods
  import { 
    searchCourse,
    validateApiKey, 
    updateActivityContents,
  } from '@/lib/functions'
  
  // Graphic components
  import DrawerRoute from '@/components/DrawerRoute.vue'
  import ItemCourse from '@/components/ItemCourse.vue'
  import SmileHeader from '@/components/SmileHeader.vue'
  
  // Assets
  import '@/assets/css/styles.css';
  
  
  export default {
  
    // Component name.
    name: 'CatalogRoutes',
  
    // Components.
    components: {
      DrawerRoute,
      ItemCourse,
      SmileHeader,
    },
  
    // Data.
    data () {
      return {
        // API key (client token).
        apiKey: null,
  
        // API token.
        token: null,
  
        // Activity id.
        activityId: null,
  
        // Whether page is fully loaded.
        loaded: false,

        // Courses.
        courses: [
          'Educación Infantil',
          '1º Educación Primaria',
          '2º Educación Primaria',
          '3º Educación Primaria',
          '4º Educación Primaria',
          '5º Educación Primaria',
          '6º Educación Primaria',
        ],

        // Subjects.
        subjects: {
          'Educación Infantil': [
            'Crecimiento en armonía',
            'Descubrimiento y exploración del entorno',
            'Comunicación y representación de la realidad',
          ],
          'Educación Primaria': [
            
            'Matemáticas',
            'Ciencias de la Naturaleza',
            'Ciencias Sociales',
            'Educación Artística',
            'Educación Física',
            'Lengua Castellana y Literatura',
            'Lengua Extranjera: Inglés',
            'Educación en Valores Cívicos y Éticos',
            'Social Science',
            'Natural Science',
            'Art Education',
            'Physical Education',
          ]
        },

        // Selected language.
        lang: 'es',

        // Course selected by the user.
        selectedCourse: null,

        // Subject selected by the user.
        selectedSubject: null,

        // Details of the course.
        courseDetails: null,

        // Whether drawer must be shown.
        drawerShow: false,

        // Details of the course to be shown in the drawer.
        drawerCourse: null,

        // Drawer language.
        drawerLang: 'es',
      }
    },
  
    // Code executed when the component is created.
    async created () {
      // Retrieves the GET parameters.
      this.apiKey = this.$route.query.apiKey
      this.token = this.$route.query.token
      this.activityId = parseInt(this.$route.query.activityId)
  
      // Validates the API key.
      if (!(await validateApiKey(this.apiKey))) this.$router.push('/no-license')
  
      // Site is finally loaded.
      this.loaded = true
    },



    // Methods.
    methods: {

      /**
       * Whether a course has already been selected.
       */
      isSelectedCourse (course) {
        return this.selectedCourse == course
      },

      /**
       * Whether to display a particular course-subject combination.
       */
      showSubject (course, subject) {
        if (course != '5º Educación Primaria' && subject == 'Educación en Valores Cívicos y Éticos') return false;
        return true;
      },

      /**
       * Whether a subject has already been selected.
       */
       isSelectedSubject (subject) {
        return this.selectedSubject == subject
      },
  
      /**
       * Retrieve contents IDs from a route.
       */
       getPathsIds (course) {
        return course.map( c => [c.id, c.contents])
      },

      /**
       * Select a course.
       */
      async selectCourse (course) {
        this.selectedCourse = course
        this.selectedSubject = null
      },
  
      /**
       * Unselect a previously selected course.
       */
      unselectCourse () {
        this.selectedCourse = null
        this.selectedSubject = null
      },
  
      /**
       * Event executed when a course is clicked.
       */
      clickCourse (course) {
        if (!this.isSelectedCourse(course)) this.selectCourse(course)
        else this.unselectCourse();
      },

      /**
       * Retrieves active subjects for the selected course.
       */
      getSubjects () {
        if (this.selectedCourse.includes('Educación Infantil')) return this.subjects['Educación Infantil'];
        if (this.selectedCourse.includes('Educación Primaria')) 
          return this.subjects['Educación Primaria'].filter((s) => this.showSubject(this.selectedCourse, s));
      },

      /**
       * Select a subject.
       */
       async selectSubject (subject) {
        this.selectedSubject = subject
        this.lang = this.getSubjectLang(subject)
        this.courseDetails = await this.getCourseDetails(this.selectedCourse, this.selectedSubject)
        updateActivityContents(this.apiKey, this.activityId, this.lang, this.getPathsIds(this.courseDetails))
        this.messageInfo(`${this.selectedCourse} - ${this.selectedSubject}`, `${this.selectedCourse} - ${this.selectedSubject}`)
      },
  
      /**
       * Unselect a previously selected subject.
       */
      unselectSubject () {
        this.selectedSubject = null
        updateActivityContents(this.apiKey, this.activityId, this.lang, [])
      },
  
      /**
       * Event executed when a course is clicked.
       */
      clickSubject (subject) {
        if (!this.isSelectedSubject(subject)) this.selectSubject(subject)
        else this.unselectSubject();
      },

      /**
       * Retrieves the search string for a given subject.
       */
      async getSubjectSearch (subject) {
        switch (subject) {
          case 'Natural Science':
            return 'Ciencias de la Naturaleza'
          case 'Social Science':
            return 'Ciencias Sociales'
          case 'Artistic Education':
            return 'Educación Artística'
          case 'Physical Education':
            return 'Educación Física'
          default:
            return subject
        }
      },

      /**
       * Retrieves the language for a given subject.
       */
      getSubjectLang (subject) {
        return ['Natural Science', 'Social Science', 'Artistic Education', 'Physical Education', 'Lengua Extranjera: Inglés'].includes(subject)  ? 'en' : 'es'
      },

      /**
       * Retrieves the details of the course.
       */
      async getCourseDetails (course, subject) {
        return await searchCourse(this.token, {
          course,
          subject: await this.getSubjectSearch(subject),
          lang: this.drawerLang,
        })
      },

      /**
       * Redirects to the contents customization view.
       */
      customizeCourse () {
        this.$router.push({
          name: 'CatalogContents', 
          query: {
            'apiKey': this.apiKey,
            'token': this.token, 
            'activityId': this.activityId, 
          }
        })
      },
  
      /**
       * Messages the info to the parent (e.g. Moodle).
       */
      messageInfo (name, desc) {
        parent.postMessage({
          type: 'smile.lti.info',
          name: name,
          description: desc,
        }, '*')
      },

      /**
       * Shows or hides the drawer.
       */
      async manageDrawer (subject) {
        if (subject) {
          this.drawerLang = this.getSubjectLang(subject)
          const drawerContents = await this.getCourseDetails(this.selectedCourse, subject)
          this.drawerCourse = {
            id: 0,
            name: `${this.selectedCourse} - ${subject}`,
            description: '',
            contents: drawerContents,
          }
        }
        this.drawerShow = subject !== null
      },
    }
  }
  </script>
  
  <style scoped>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3f87c7;
  }
  .catalog-message {
    max-width: 800px; 
    margin: 0 auto; 
    text-align: center;
  }
  </style>