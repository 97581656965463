<template>

<!-- Main container (when everything is ready) -->
<div v-if="loaded">

  <!-- Smile and Learn header -->
  <smile-header 
    :welcome="false"
    :show-button="true"
    :api-key="apiKey"
    :token="token"
    :activity-id="activityId"
    :lang="lang"
  />

  <!-- Heading message -->
  <div class="catalog-message">
    <img :src="challengeInfo.image_url" :alt="challengeInfo.title" class="logo" />
    <h1>{{ challengeInfo.title }}</h1>
    <p>{{ challengeInfo.description }}</p>
  </div>

  <!-- Routes panel (only when some routes are to be displayed) -->
  <div id="sl-routes" class="sl-routes" v-if="routes.length > 0 || selectedRoute !== null">
    <item-route
      v-for="item in routes" 
      :key="item.id" 
      :item="item" 
      :is-selected="isSelected(item.id)"
      :hideCustomize="true"
      @toggle-select-route="clickItem"
      @manage-drawer="manageDrawer"
    />
  </div>

  <!-- Routes information panel -->
  <drawer-route
    :visible="drawerShow"
    :token="token"
    :route-id="drawerRoute?.id"
    :lang="lang"
    @manage-drawer="manageDrawer"
  />
</div>

<!-- Alternative container when data is loading -->
<div v-else>
  <div class="unloaded">
    <span class="bg-lineas-diagonales">
      Cargando...
    </span>
  </div>
</div>

</template>

<script>

// Methods
import { 
  validateApiKey, 
  updateActivityContents,
  getChallengeDetails,
  getRouteDetails,
} from '@/lib/functions'

// Graphic components
import DrawerRoute from '@/components/DrawerRoute.vue'
import ItemRoute from '@/components/ItemRoute.vue'
import SmileHeader from '@/components/SmileHeader.vue'

// Assets
import '@/assets/css/styles.css';


export default {

  // Component name.
  name: 'CatalogRoutes',

  // Components.
  components: {
    DrawerRoute,
    ItemRoute,
    SmileHeader,
  },

  // Data.
  data () {
    return {
      // API key (client token).
      apiKey: null,

      // API token.
      token: null,

      // Activity id.
      activityId: null,

      // Challenge id.
      challengeId: null,

      // Whether page is fully loaded.
      loaded: false,

      // Chosen language.
      lang: 'es',

      // Challenge info.
      challengeInfo: {},

      // Challenge routes.
      routes: [],

      // Route selected by the user.
      selectedRoute: null,

      // Whether drawer must be shown.
      drawerShow: false,

      // Route to be displayed in the drawer.
      drawerRoute: null,
    }
  },

  // Code executed when the component is created.
  async created () {
    // Retrieves the GET parameters.
    this.apiKey = this.$route.query.apiKey
    this.token = this.$route.query.token
    this.activityId = parseInt(this.$route.query.activityId)
    this.challengeId = parseInt(this.$route.query.challengeId)

    // Handle scroll event.
    window.onscroll = this.handleScroll

    // Validates the API key.
    if (!(await validateApiKey(this.apiKey))) this.$router.push('/no-license')

    // Loads routes.
    const details = await getChallengeDetails(this.token, this.challengeId, this.lang)
    this.challengeInfo = details.challenge
    this.routes = details.paths

    // Site is finally loaded.
    this.loaded = true
  },

  // Code executed for handling events when the page is unloaded.
  unmounted () {
    window.onscroll = null
  },

  // Methods.
  methods: {
    
    /**
     * Changes the language of the activity.
     */
    changeLang (lang) {
      this.lang = lang
    },

    /**
     * Whether a route has already been selected.
     */
    isSelected (route_id) {
      return this.selectedRoute?.id == route_id
    },

    /**
     * Retrieve contents IDs from a route.
     */
    getContentsIds (route) {
      return route.contents.map( c => c.id )
    },

    /**
     * Select a route from the search results.
     */
    async selectRoute (route) {
      this.selectedRoute = await getRouteDetails(this.token, this.lang, route.id)
      updateActivityContents(this.apiKey, this.activityId, this.lang, this.getContentsIds(this.selectedRoute))
      const name = `${this.challengeInfo.title}: ${this.selectedRoute.name}`
      const description = `${this.challengeInfo.title}: ${this.selectedRoute.description}`
      this.messageInfo(name, description)
    },

    /**
     * Unselect a previously selected route.
     */
    unselectRoute () {
      this.selectedRoute = null
      updateActivityContents(this.apiKey, this.activityId, this.lang, [])
      this.messageInfo('', '')
    },

    /**
     * Event executed when a route from the search results is clicked.
     */
    clickItem (route) {
      if (!this.isSelected(route.id)) this.selectRoute(route)
      else this.unselectRoute();
    },

    /**
     * Messages the info to the parent (e.g. Moodle).
     */
    messageInfo (name, desc) {
      parent.postMessage({
        type: 'smile.lti.info',
        name: name,
        description: desc,
      }, '*')
    },

    /**
     * Shows or hides the drawer.
     */
    manageDrawer (route) {
      this.drawerRoute = route
      this.drawerShow = route !== null
    },

    /**
     * Handles the scroll of the site.
     */
    handleScroll () {      
      var header = document.getElementById("sl-search")
      var routes = document.getElementById("sl-routes")
      let height = header.offsetHeight
      if (routes) {
        if (window.pageYOffset > 278) {
          header.classList.add("sticky")
          routes.style.marginTop = height + 'px'
        } else {
          header.classList.remove("sticky")
          routes.style.marginTop = '0px'
        }
      }
    }
  }
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3f87c7;
}
.logo {
  max-width: 150px; 
  margin: 0 auto; 
}
.catalog-message {
  max-width: 800px; 
  margin: 0 auto; 
  text-align: center;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  margin: 60px 0;
}
.unloaded {
  text-align: center; 
  margin: 20px;
}
.unloaded span {
  color: white; 
  padding: 10px 20px; 
  font-weight: 600; 
  border-radius: 10px;
}
</style>