<template>
  <router-view />
  <footer v-if="isEducamadrid" >
      <img class="logo-cam" src="@/assets/madrid.svg" alt="Comunidad de Madrid" />
      <p class="text-mid">El Fondo Social Europeo invierte en tu futuro.<br />Financiado como parte de la respuesta de la Unión a la pandemia COVID19.</p>
      <img class="logo-eu" src="@/assets/ue.svg" alt="Unión Europea" />
      <p class="text-eu">
        <span class="h1-eu">UNIÓN EUROPEA</span>
        <br />
        <span class="h2-eu">Fondo Social Europeo</span>
        <br />
        <span class="h3-eu">El FSE invierte en tu futuro</span>
      </p>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      isEducamadrid: false
    }
  },
  mounted () {     
    this.isEducamadrid = document.referrer.includes('educa.madrid.org');
  }
}
</script>

<style scoped>
@font-face {
  font-family: Bradley;
  src: url(@/assets/bradley.ttf);
}
footer {
  position: fixed;
  bottom: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  background-color: #E2E8EF;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  max-width: 25%;
  max-height:15%;
  float: left;
}
.logo-cam {
  margin-right: 2em;
  max-height: 70px;
}
.text-mid {
  font-family: Bradley;
  font-size: 10pt;
  color: #00A;
  margin-right: 2em;
  line-height: normal;
}
.logo-eu {
  margin-right: 1em;
  max-height: 50px;
}

.text-eu {
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  line-height: 12pt;
}
.h1-eu {
  font-size: 12pt;
  font-weight: 900;
  line-height: 14pt;
}
.h2-eu {
  font-size: 10pt;
  font-weight: 400;
  line-height: 8pt;
}
.h3-eu {
  font-size: 8pt;
  font-weight: 300;
}
</style>